<template lang="pug">
app-layout
  .page-blog
    .t-h1-mobile.mb-6 Блог

    template(v-if="articles.length")
      blog-card(v-for="article in articles" :key="article.id" :article="article")

      .mt-10(v-if="total > articles.length")
        ui-btn.mb-4(
          v-if="100 > 9"
          min-height=40
          preset="rounded"
          width="100%"
          @click="fetchArticles"
        )
          template(v-if="!loading")
            ui-icon(name="renew" left)
            | Показать ещё
          ui-loader.d-flex(v-else preloader text="Загрузка")
        .t-caption-normal.text-center.c-black60 Показано {{ articles.length }} из {{ total }}
    template(v-else)
      ui-loader.d-flex(preloader text="Загрузка")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import { UiBtn, UiIcon } from '@/uikit';
  import BlogCard from '@/components/BlogCard.vue';
  import { UiLoader } from '@/uikit';

  import { service as API } from '@/services/pages';
  import { handleError } from '@/libs/handle-error';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageBlog', 'blue');

  export default {
    name: 'page-blog',
    components: { AppLayout, UiBtn, UiIcon, BlogCard, UiLoader },
    data() {
      return {
        articles: [],
        total: undefined,
        loading: false,
        page: 1,
      };
    },

    created() {
      this.fetchArticles();
    },

    methods: {
      async fetchArticles() {
        if (this.loading) return;

        try {
          this.loading = true;
          if (!this.total || this.total > this.articles.length) {
            const data = await API.blog(this.page);

            this.page = this.page + 1;
            this.articles = this.articles.concat(data.articles);
            this.total = data.total;
          }
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-blog {
    padding: 32px 16px;
  }
</style>
